/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

/*
 * Footer used across all the pages
 */
import React from "react";
import { SwmcFooter } from "footer-component";
import "./footerNew.module.css";

const CustomFooter = () => {
const isSwmcUrl = window.location.hostname.includes("swmc.com");

  return (
    <div>
      <SwmcFooter type="swmc" showTacanewLogo={false} nydisclaimer={!isSwmcUrl} />
    </div>
  );
};

export default CustomFooter;
